import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import RichHeader from './richHeader'

import meetBottom from '../images/meet-bottom.png'
import posterImage from '../images/video-poster.png'

const Meet = () => {
    const data = useStaticQuery(graphql`
        query MeetQuery {
            contentfulMarketingPage {
                meetHeader {
                    content {
                        content {
                            nodeType
                            value
                        }
                        nodeType
                    }
                }
                demoVideo {
                    label
                    file {
                        file {
                            url
                            fileName
                            contentType
                        }
                    }
                }
            }
        }
    `)

    const { meetHeader, demoVideo } = data.contentfulMarketingPage

    return (
        <div id="meet-ipsa">
            <div className="section-wrapper">
                <RichHeader content={meetHeader.content} />

                <div className="embed-container">
                    <video
                        controls
                        name="media"
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                        }}
                        poster={posterImage}
                    >
                        <source src={demoVideo.file.file.url} allowFullScreen></source>
                    </video>
                </div>
            </div>

            <img src={meetBottom} className="bottom-curve" alt="" />
        </div>
    )
}

export default Meet
