import React from 'react'
import PropTypes from 'prop-types'

const RichHeader = props => {
    const { content } = props

    const getStyleObject = content => {
        if(!content.marks || !content.marks.length)
            return null;

        const result = {};
        content.marks.forEach(m => {
            switch(m.type) {
                case 'underline':
                    result.textDecoration = 'underline';
                    break;
                case 'bold':
                    result.fontWeight = 'bold';
                    break;
                default:
                    break;
            }            
        });

        return result
    }

    const contactValues = content => {
        return content.map((c, i) => {
            const styleObj = getStyleObject(c);
            const value = c.value.replace(/\u00A0/gi, ' ');
            if(!styleObj)
                return value;

            const Span = 'span';
            return <Span key={i} style={styleObj}>{value}</Span>;
        }); 
    }

    const getTagName = nodeType => {
        switch (nodeType) {
            case 'heading-1':
                return 'h1'
            case 'heading-2':
                return 'h2'
            case 'heading-3':
                return 'h3'
            default:
                return 'p'
        }
    }

    const renderNode = (node, i) => {
        const TagName = getTagName(node.nodeType)
        const result = contactValues(node.content)
        if (result) return <TagName key={i}>{result}</TagName>
        else return ''
    }

    return content.map(renderNode)
}

export default RichHeader

RichHeader.propTypes = {
    content: PropTypes.array.isRequired,
}
