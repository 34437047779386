import React from 'react'

const IntroBackground = () => {
    return (
        <div id="intro-bg">
            <svg width="1872px" height="1872px" viewBox="0 0 1872 1872" version="1.1">
                <title>icon</title>
                <g
                    id="Marketing-Website"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <g
                        id="icon"
                        transform="translate(936.000000, 936.000000) rotate(-360.000000) translate(-936.000000, -936.000000) translate(0.000000, 0.000000)"
                        fill="#120A42"
                    >
                        <path
                            d="M1804.55696,936 L1804.55696,936.328101 C1804.37468,1369.85924 1451.59291,1722.53165 1018.02532,1722.53165 C584.311899,1722.53165 231.493671,1369.67696 231.493671,936 C231.493671,502.323038 584.311899,149.468354 1018.02532,149.468354 C1451.59291,149.468354 1804.37468,502.140759 1804.55696,935.671899 L1804.55696,936 Z M936,0 C419.896709,0 0,419.896709 0,936 C0,1452.10329 419.896709,1872 936,1872 C1452.10329,1872 1872,1452.10329 1872,936 C1872,419.896709 1452.10329,0 936,0 L936,0 Z"
                            id="Fill-8"
                        ></path>
                        <path
                            d="M1635.49945,975.291263 C1617.74544,1257.49687 1382.53226,1481.65708 1095.95277,1481.65708 C797.853226,1481.65708 555.348867,1239.12069 555.348867,941.01823 C555.348867,642.87931 797.853226,400.342915 1095.95277,400.342915 C1382.53226,400.342915 1617.74544,624.503131 1635.49945,906.708737 C1636.11919,918.048007 1636.41084,929.496658 1636.41084,941.01823 C1636.41084,952.503342 1636.11919,963.951993 1635.49945,975.291263 M1013.92709,251 C633.510223,251 324,560.551111 324,941.01823 C324,1321.48535 633.510223,1631 1013.92709,1631 C1381.51149,1631 1682.89206,1342.04917 1702.83341,979.301937 C1703.59899,966.650083 1704,953.852387 1704,941.01823 C1704,928.147613 1703.59899,915.349917 1702.83341,902.698063 C1682.89206,539.987292 1381.51149,251 1013.92709,251"
                            id="Fill-6"
                        ></path>
                        <path
                            d="M1185.05339,1233.48049 C1022.69708,1233.48049 890.572895,1101.3563 890.572895,939 C890.572895,776.607228 1022.69708,644.519507 1185.05339,644.519507 C1347.40969,644.519507 1479.53388,776.607228 1479.53388,939 C1479.53388,1101.3563 1347.40969,1233.48049 1185.05339,1233.48049 M1103,495 C858.189158,495 659,694.15269 659,939 C659,1183.81084 858.189158,1383 1103,1383 C1347.81084,1383 1547,1183.81084 1547,939 C1547,694.15269 1347.81084,495 1103,495"
                            id="Fill-4"
                        ></path>
                        <path
                            d="M1185,743 C1074.18138,743 984,833.181382 984,944 C984,1054.81862 1074.18138,1145 1185,1145 C1295.81862,1145 1386,1054.81862 1386,944 C1386,833.181382 1295.81862,743 1185,743"
                            id="Fill-11"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default IntroBackground
