import React from 'react'
import RichHeader from './richHeader'
import { useStaticQuery, graphql } from 'gatsby'

import topLg from '../images/journey-top-lg.png'
import topSm from '../images/journey-top-sm.png'

const Journey = () => {
    const data = useStaticQuery(graphql`
        query StartQuery {
            contentfulMarketingPage {
                journeyHeader {
                    content {
                        content {
                            value
                        }
                        nodeType
                    }
                }
                contactEmail
                contactPhone
            }
        }
    `)

    const { journeyHeader, contactPhone, contactEmail } = data.contentfulMarketingPage
    return (
        <div id="journey">
            <div id="journey-top">
                <img id="journey-top-lg" src={topLg} alt="" />
                <img id="journey-top-sm" src={topSm} alt="" />
            </div>
            <div className="section-wrapper">
                <div className="journey-header">
                    <RichHeader content={journeyHeader.content} />
                </div>
                {contactEmail && (
                    <div className="contact-info">
                        <b>Email: </b>
                        <a href={`mailto:${contactEmail}?subject=Contact IPSA`}>{contactEmail}</a>
                    </div>
                )}
                {contactPhone && (
                    <div className="contact-info">
                        <b>Phone: </b>
                        <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Journey
