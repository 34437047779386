import React from 'react'
import PropTypes from 'prop-types'
import Nav from './carouselSupport/nav.js'

const OwlCarousel =
    typeof window !== `undefined` ? require('react-owl-carousel') : 'owl-carousel-mock'

const TestimonialCarousel = props => {
    const { quotes } = props

    const nav = imagePath => `<img src="${imagePath}" width="14" />`

    return (
        <OwlCarousel
            id="quote-list"
            loop
            dots={false}
            nav={true}
            navText={Nav}
            smartSpeed={1000}
            responsiveClass={true}
            autoplayHoverPause={true}
            responsive={{
                0: {
                    items: 1,
                    margin: 20,
                },
            }}
        >
            {quotes.map((item, i) => (
                <div key={i}>
                    <h3>{item.title}</h3>
                    <p>{item.quote.quote}</p>
                </div>
            ))}
        </OwlCarousel>
    )
}

export default TestimonialCarousel

TestimonialCarousel.propTypes = {
    quotes: PropTypes.array.isRequired,
}
