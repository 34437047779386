import React from 'react'

import Layout from '../components/layout'
import Intro from '../components/intro'
import Meet from '../components/meet'
import Explore from '../components/explore'
import Testamonials from '../components/testimonials'
import Journey from '../components/journey'
import SEO from '../components/seo'

import '../sass/core.scss'

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="" />
            <Intro />
            <Meet />
            <Explore />
            <Testamonials />
            <Journey />
        </Layout>
    )
}

export default IndexPage
