import React, { useState } from 'react'
import RichHeader from './richHeader'
import RingInfoItem from './ringInfoItem'
import { useStaticQuery, graphql } from 'gatsby'
import {leftArrow, rightArrow} from './carouselSupport/arrows.js'

import bottomLg from '../images/explore-bottom-lg2.png'
import bottomSm from '../images/explore-bottom-sm2.png'

const Explore = () => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const data = useStaticQuery(graphql`
        query ExploreQuery {
            contentfulMarketingPage {
                exploreHeader {
                    content {
                        content {
                            nodeType
                            value
                        }
                        nodeType
                    }
                }
                exploreRings {
                    label
                    description {
                        description
                    }
                }
            }
        }
    `)

    const { exploreHeader, exploreRings } = data.contentfulMarketingPage
    const setRingOpacity = index => {
        return index === currentIndex ? 1 : 0.15
    }

    return (
        <div id="explore">
            <div className="col-section col-no-margin">
                <div id="ring-nav" className="col-xs-12 col-md-6">
                    <svg
                        width="582px"
                        height="582px"
                        viewBox="0 0 582 582"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g
                            id="Portal"
                            stroke="none"
                            strokeWidth="1"
                            fill="#0f0835"
                            fillRule="evenodd"
                        >
                            <g
                                id="OP-Portal-–-Dashboard----Simple-v1"
                                transform="translate(-681.000000, -166.000000)"
                            >
                                <g id="icon" transform="translate(681.000000, 166.000000)">
                                    <path
                                        d="M561.032132,291 L561.032132,291.102006 C560.975463,425.885725 451.296514,535.530672 316.501461,535.530672 C181.661071,535.530672 71.9707887,425.829056 71.9707887,291 C71.9707887,156.170944 181.661071,46.4693281 316.501461,46.4693281 C451.296514,46.4693281 560.975463,156.114275 561.032132,290.897994 L561.032132,291 Z M291,0 C130.54481,0 0,130.54481 0,291 C0,451.45519 130.54481,582 291,582 C451.45519,582 582,451.45519 582,291 C582,130.54481 451.45519,0 291,0 L291,0 Z"
                                        data-ring-number="1"
                                        fill="#3B2BB3"
                                        opacity={setRingOpacity(0)}
                                        onMouseEnter={() => setCurrentIndex(0)}
                                    ></path>
                                    <path
                                        d="M508.705262,303.16011 C503.186083,390.889244 430.065462,460.573833 340.976621,460.573833 C248.306546,460.573833 172.919322,385.176649 172.919322,292.505667 C172.919322,199.823351 248.306546,124.426167 340.976621,124.426167 C430.065462,124.426167 503.186083,194.110756 508.705262,281.83989 C508.897924,285.364924 508.988588,288.923961 508.988588,292.505667 C508.988588,296.076039 508.897924,299.635076 508.705262,303.16011 M315.477334,78 C197.217309,78 101,174.230019 101,292.505667 C101,410.781315 197.217309,507 315.477334,507 C429.748138,507 523.438184,417.173981 529.637343,304.406906 C529.875337,300.47383 530,296.495416 530,292.505667 C530,288.504584 529.875337,284.52617 529.637343,280.593094 C523.438184,167.837354 429.748138,78 315.477334,78"
                                        data-ring-number="2"
                                        fill="#1E90D9"
                                        opacity={setRingOpacity(1)}
                                        onMouseEnter={() => setCurrentIndex(1)}
                                    ></path>
                                    <path
                                        d="M368.50308,383.527721 C318.040986,383.527721 276.975359,342.462094 276.975359,292 C276.975359,241.526571 318.040986,200.472279 368.50308,200.472279 C418.965175,200.472279 460.030801,241.526571 460.030801,292 C460.030801,342.462094 418.965175,383.527721 368.50308,383.527721 M343,154 C266.910144,154 205,215.898809 205,292 C205,368.089856 266.910144,430 343,430 C419.089856,430 481,368.089856 481,292 C481,215.898809 419.089856,154 343,154"
                                        data-ring-number="3"
                                        fill="#00F6FF"
                                        opacity={setRingOpacity(2)}
                                        onMouseEnter={() => setCurrentIndex(2)}
                                    ></path>
                                    <path
                                        d="M368.5,231 C334.041475,231 306,259.041475 306,293.5 C306,327.958525 334.041475,356 368.5,356 C402.958525,356 431,327.958525 431,293.5 C431,259.041475 402.958525,231 368.5,231"
                                        data-ring-number="4"
                                        fill="#28FF65"
                                        opacity={setRingOpacity(3)}
                                        onMouseEnter={() => setCurrentIndex(3)}
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <div id="explore-text" className="col-xs-12 col-md-6">
                    <div className="section-wrapper">
                        <RichHeader content={exploreHeader.content} />
                        <div id="ring-info-container">
                            {exploreRings
                                .slice(0)
                                .reverse()
                                .map((ringInfo, i) => (
                                    <RingInfoItem
                                        key={i}
                                        item={ringInfo}
                                        myIndex={i}
                                        currentIndex={currentIndex}
                                    />
                                ))}
                        </div>
                        <div id="ring-info-nav">
                            <img
                                id="ring-info-nav-left"
                                onClick={() =>
                                    setCurrentIndex(
                                        (currentIndex - 1 < 0
                                            ? exploreRings.length
                                            : currentIndex) - 1
                                    )
                                }
                                src={leftArrow}
                                width="14"
                            />
                            <img
                                id="ring-info-nav-right"
                                onClick={() =>
                                    setCurrentIndex((currentIndex + 1) % exploreRings.length)
                                }
                                src={rightArrow}
                                width="14"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id="explore-bottom">
                <img id="explore-bottom-lg" src={bottomLg} alt="" />
                <img id="explore-bottom-sm" src={bottomSm} alt="" />
            </div>
        </div>
    )
}

export default Explore
