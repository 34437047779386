import React, { useState } from 'react'
import RichHeader from './richHeader'
import TestimonialCarousel from './testimonialCarousel'
import { useStaticQuery, graphql } from 'gatsby'

const Testimonials = () => {
    const data = useStaticQuery(graphql`
        query TestimonialQuery {
            contentfulMarketingPage {
                testimonialHeader {
                    content {
                        content {
                            value
                        }
                        nodeType
                    }
                }
                testimonialQuotes {
                    quote {
                        quote
                    }
                    title
                }
            }
        }
    `)

    const { testimonialHeader, testimonialQuotes } = data.contentfulMarketingPage
    return (
        <div id="quotes">
            <div className="section-wrapper">
                <div className="col-section col-no-margin">
                    <div className="col-xs-12 col-md-6">
                        <RichHeader content={testimonialHeader.content} />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <TestimonialCarousel quotes={testimonialQuotes} />
                    </div>
                    <div className="col-xs-12 col-md-6"></div>
                    <div className="col-xs-12 col-md-6">
                        <a id="quote-button" className="btn free-trial-button" href="/signup">
                            CLICK HERE TO START YOUR TRIAL!
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
