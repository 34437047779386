import React from 'react'
import PropTypes from 'prop-types'

const RingInfoItem = props => {
    const { item, myIndex, currentIndex } = props

    return (
        <div
            id={`ring-info-${myIndex + 1}`}
            className="ring-info"
            data-is-active={currentIndex === myIndex}
        >
            <h3 className="explore-line">{item.label}</h3>
            <p>{item.description.description}</p>
        </div>
    )
}

export default RingInfoItem

RingInfoItem.propTypes = {
    item: PropTypes.object.isRequired,
    myIndex: PropTypes.number.isRequired,
    currentIndex: PropTypes.number.isRequired,
}
