import React from 'react'
import SignIn from './signin.js'

import { useStaticQuery, graphql } from 'gatsby'

import mainLogo from '../images/OP_Portal_Main_logo.svg'
import arrowDown from '../images/OP_Arrow_Down_Light.svg'
import topCurve from '../images/top-curve.png'
import IntroBackground from './introBackground.js'
import bringIntoView from './bringIntoView'

const Intro = () => {
    const data = useStaticQuery(graphql`
        query IntroQuery {
            contentfulMarketingPage {
                header
                headerSubtitle
                headerDisclaimer
                introVideo {
                    label
                    file {
                        file {
                            url
                            fileName
                            contentType
                        }
                    }
                }
            }
        }
    `)

    const { header, headerSubtitle, headerDisclaimer, introVideo } = data.contentfulMarketingPage

    return (
        <div id="intro">
            <div id="intro-text" className="section-wrapper">
                <SignIn introVideo={introVideo} />
                <h1>{header}</h1>
                <h2>{headerSubtitle}</h2>
                <h3 className="disclaimer">{headerDisclaimer}</h3>

                <button
                    id="demo"
                    className="btn free-trial-button "
                    onClick={bringIntoView.bind(this, '#meet-ipsa')}
                >
                    WATCH DEMO
                </button>
                <button
                    id="free-trial-signup"
                    className="btn btn-purple"
                    onClick={bringIntoView.bind(this, '#quotes')}
                >
                    30-DAY TRIAL
                </button>
                <button class="next-arrow" onClick={bringIntoView.bind(this, '#meet-ipsa')}>
                    LEARN MORE <img src={arrowDown} alt="arrow down" />
                </button>
            </div>
            <IntroBackground />
            <img src={topCurve} className="section-curve" />
        </div>
    )
}

export default Intro
